/**
 * Measurement Component code
 */

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// Import Interface
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import sensorData from '../../../assets/data/sensorTypeConfig.json';
import { Conversion } from '../../models/converterData';
import { ChartsSettingsService } from '../../services/charts-settings.service';
import { ConversionService } from '../../shared/services/conversion.service';
import * as unitConversionAction from '../../state/actions/conversionActions';
import { State } from '../../state/container-states/app.state';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import { MatSelect } from '@angular/material/select';


@Component({
  selector: 'app-unit-converter',
  templateUrl: './unit-converter.component.html',
  styleUrls: ['./unit-converter.component.scss'],
})
export class UnitConverterComponent implements OnInit, AfterViewInit {
  @ViewChild('timezoneSelect') timezoneSelect: MatSelect;
  @ViewChild('searchInput') searchInput: ElementRef;
  isDesktopDevice = false;
  axisList = [];
  exportForm: FormGroup;
  timezones = [];
  timezone: any;
  filteredTimezones = [];
  searchTerm = '';
  timezoneLabels: Map<string, string> = new Map();
  
  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store<State>,
    private deviceService: DeviceDetectorService,
    private conversionService: ConversionService,
    private chartsSettingsService: ChartsSettingsService
  ) {
    this.timezones = moment.tz.names();
    this.filteredTimezones = this.timezones.slice();
    this.sensorTypes = sensorData.sensorData.filter((item) => {
      return item.settings === true;
    });
    this.exportForm = new FormGroup({
      PressureUnits: new FormControl(),
      TemperatureUnits: new FormControl(),
      PitchangerateUnits: new FormControl(''),
      GasrateUnits: new FormControl(''),
      LiquidrateUnits: new FormControl(''),
    });
    //Preload timezone labels
    this.timezoneLabels = new Map(
      this.timezones.map((timezone) => [timezone, this.computeTimezoneLabel(timezone)])
    );
  }

  ngOnInit() {
    this.store
      .select((state: State) => state.conversion)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((conversionState) => {
        if (conversionState) {
          this.converterData = conversionState.conversion;
          this.timezone = this.converterData.timeUnit;
        }
      });
  }

  @Input()
  openDialog = false;
  @Input()
  unitData: Conversion;
  @Output()
  closeModal = new EventEmitter();
  unsubscribe$ = new Subject();
  converterData: Conversion;
  public sensorTypes: any[];

  public conversionData: Conversion;

  selectedTimeUnit: string;
  public defaultValue: string;
  public selectTimeOptions;

  public ngAfterViewInit(): void {
    this.timezone = this.timezone ? this.timezone : moment.tz.guess();
    if (this.unitData) {
      this.conversionData = this.unitData;
      this.sensorTypes.forEach((sensorItem) => {
        //this.checkData(this.unitData[sensorList.sensorType + 'Unit']);
        sensorItem.selectedValue = this.unitData[sensorItem.sensorType + 'Unit'];
       // this.setDefaultValues(sensorType);
      });
      this.defaultValue = this.unitData.timeUnit;
      this.selectTimeOptions = [
        {
          name: 'UTC',
          value: 'UTC',
        },
        {
          name: 'CST',
          value: 'CST',
        },
      ];
    }
    this.cdr.detectChanges();
    this.getDeviceInfo();
  }

  // Filter timezones based on the search term
  filterTimezones(): void {
    const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
    this.filteredTimezones = this.timezones.filter((timezone) =>
      timezone.toLowerCase().includes(lowerCaseSearchTerm)
    );
  }

   // When the select is opened, reset the filtered list to include all options
  onSelectOpened(opened: boolean): void {
    if (opened) {
      this.filteredTimezones = this.timezones.slice();
      this.searchTerm = '';
      //this.searchInput.nativeElement.focus();
      setTimeout(() => this.searchInput?.nativeElement.focus(), 0);
    }
  }

  public selection(event: string): void {
    this.conversionData.timeUnit = event;
  }
  scrollToDialog() {
    window.scrollTo({
      left: 0,
      top: 100
    });
  }
  scrollToInitial() {
    window.scrollTo({
      left: 0,
      top: 0
    });
  }

  checkData(value: string) {
    document.querySelector('input[value=' + value + ']')['checked'] = true;
  }
  setDefaultValues(sensorType){
    const defaultItem = sensorType.sensorUnits.find(item => item.default);
    if (defaultItem) {
      sensorType.selectedValue = defaultItem.value;
    }
  }

  public closeDialog(): void {
    this.openDialog = false;
    this.closeModal.emit();
  }

  saveChanges() {
    const userDetails = JSON.parse(localStorage.getItem('currentUser'));
    const unitConversion = {};
    this.sensorTypes.forEach((sensorList) => {
     // unitConversion[sensorList.sensorType + 'Unit'] = document.querySelector('input[name=' + sensorList.sensorType + ']:checked')['value'];
      unitConversion[sensorList.sensorType + 'Unit'] = sensorList.selectedValue;
    });
    unitConversion['timeUnit'] = this.timezone;
    // unitConversion['timeLabel'] = document.getElementById('select2-select-container').title;
    unitConversion['timeLabel'] = this.getTimezoneLabel(this.timezone);
    unitConversion['userId'] = userDetails['email'];

    //get the previous selected units for all the types of sensors
    const pressureFromUnit = this.converterData['pressureUnit'].toLowerCase();
    const temperatureFromUnit = this.converterData['temperatureUnit'].toLowerCase();
    const flowFromUnit = this.conversionData['pitchangerateUnit'].toLowerCase();

    //get the current selected units for all the types of sensors
    const pressureToUnit = unitConversion['pressureUnit'].toLowerCase();
    const temperatureToUnit = unitConversion['temperatureUnit'].toLowerCase();
    const flowToUnit = unitConversion['pitchangerateUnit'].toLowerCase();

    //get the axis list from the local storage for the current user
    const userObjectFromLocal = JSON.parse(localStorage.getItem('currentUser'));
    if (userObjectFromLocal != null) {
      this.chartsSettingsService.getChartsSelectSettings(userObjectFromLocal['email']).subscribe((result) => {
        if ((result !== undefined) && (result && result.length !== 0) && (result[0].value.length !== 0)) {
          this.axisList = [];
          for (const res of result[0].value) {
            this.axisList.push(res);
          }
          //check all the axis for min and max uom values and convert to new selected units and save in ChartsTable in db
          this.axisList.forEach((axes) => {
            if (!axes.autoRange) {
              if (axes.value.toLowerCase().indexOf('pressure') != -1 && pressureToUnit != pressureFromUnit) {
                axes.min = this.conversionService.convertCurrentPressure(pressureFromUnit, Number(axes.min), pressureToUnit);
                axes.max = this.conversionService.convertCurrentPressure(pressureFromUnit, Number(axes.max), pressureToUnit);
              }
              if (axes.value.toLowerCase().indexOf('temperature') != -1 && temperatureToUnit != temperatureFromUnit) {
                axes.min = this.conversionService.convertCurrentTemperature(temperatureFromUnit, axes.min, temperatureToUnit);
                axes.max = this.conversionService.convertCurrentTemperature(temperatureFromUnit, axes.max, temperatureToUnit);
              }
              if (axes.value.toLowerCase().indexOf('pitchangerateUnit') != -1 && flowToUnit != flowFromUnit) {
                axes.min = this.conversionService.convertCurrentPitchangerate(flowFromUnit, Number(axes.min), flowToUnit);
                axes.max = this.conversionService.convertCurrentPitchangerate(flowFromUnit, Number(axes.max), flowToUnit);
              }
            }
          });

          this.chartsSettingsService.insertDBChartsTable(this.axisList);
        }
        const payload = {
          user: userDetails,
          unitConversion
        };
        this.store.dispatch(new unitConversionAction.UpdateUnitConversion(payload));
        this.closeDialog();
      });
    }
  }

  changeTimezone(event) {
    this.timezone =  event.value;
  }

  /**
   * Returns the timezone label formatted with GMT offset if specified.
   * @param timezone - The name of the timezone to format.
   * @returns A formatted string representing the timezone, optionally including the GMT offset.
   */
  getTimezoneLabel(timezone: string): string {
    return this.timezoneLabels.get(timezone) || timezone;
  }

  private computeTimezoneLabel(timezone: string): string {
    // Calculate the offset in hours from UTC
    const offset = moment.tz(timezone).utcOffset() / 60;
    // Determine the sign of the offset
    const sign = offset >= 0 ? '+' : '-';
    // Format the offset as a string in HH:MM format
    const formattedOffset = `${sign}${Math.abs(offset).toString().padStart(2, '0')}:00`;
    return `(GMT${formattedOffset}) ${timezone}`;
  }
  

  getDeviceInfo() {
    this.deviceService.isDesktop() ? this.isDesktopDevice = true : this.isDesktopDevice = false;
  }
  ngOnDestroy() {
  }
}
