import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { OKTA_CONFIG } from '@okta/okta-angular';
import 'hammerjs';
import 'zone.js/dist/zone';
import { AppModule } from './app/app.module';

function determineEnvironment(): string {
  const hostname = window.location.hostname.toLowerCase();

  // TODO refactor this when there are no more VMs
  if (hostname.includes('localhost')) {
    return 'local';
  }
  if (hostname.includes('dev')) {
    return 'dev';
  }
  if (hostname.includes('qa')) {
    return 'qa';
  }
  if (hostname.includes('stg') || hostname.includes('stage')) { //stg.clariti.ienergy.com
    return 'stg';
  }
  return 'scus';
}

const env = determineEnvironment();

const configPath = `/assets/environments/environment-${env}.json`;

fetch(configPath)
  .then((response) => response.json())
  .then((data_json) => {
    let redirectUri = window.location.origin;

     const config = {
      clientId: data_json['clientId'],
      issuer: data_json['issuer'],
      redirectUri: `${redirectUri}/implicit/callback`,
      scopes: ['openid', 'profile', 'email'],
      pkce: data_json['pkce'],
      tokenManager: {
        autoRenew: false,
        expireEarlySeconds: 600 // setting the 10 min for this leads to 'tokenManager.hasExpired()' returns true before 10 min
      }
    };
    enableProdMode();
    const bootstrap = () =>
      platformBrowserDynamic([
        { provide: OKTA_CONFIG, useValue: config },
        { provide: 'ENV_CONFIG', useValue: data_json }
      ]).bootstrapModule(AppModule);
    bootstrap().catch((err) => console.log(`Main: ${err}`));
  })
  .catch((error) => {
    console.error('Error occurred in Main:', error);
  });
