<div>
  <div class='container main'>
  <form [formGroup]='exportForm' (ngSubmit)="onSubmit()">
    <div class='col-xs-12 select-well'>
      <span class='select-well-text'>
        {{ wellName ? wellName : "Select a well to begin" }}
      </span>
      <div>
      <button type="button" DefaultFocus class="btn-secondary" tabindex="0" id='select-well' (click)='openModal($event.target.id)'>Select Well</button>
    </div>
    </div>
    <div class='col-xs-12 form'>
      <div class='row form-section data-settings-section'>
        <div class='col-xs-12 col-sm-3 section-header text-align-center'>
          <span class='header-text data-settings-text'>Data Settings</span>
        </div>

        <div class="col-xs-12 col-sm-9">
          <div class='row input-container'>
            <label class='col-xs-12 label dropdown-label padding-top-10'>
              Time & Date
            </label>

            <div class='col-xs-12' >
              <mat-form-field class='dateTimeOptions' appearance="outline">
                <!-- <mat-label>Date Time Format</mat-label> -->
                <mat-select formControlName="DateTimeFormat">
                  <mat-option *ngFor="let option of DateTimeFormatOptions" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class='subtext choose-date' *ngIf='jobDateRange'>
            Please select a date range between {{ jobDateRangeConverted.start | date: 'dd-MMM-yy' }}
            and {{ jobDateRangeConverted.end | date: 'dd-MMM-yy' }}.
          </div>

          <div class='row input-container datepicker'>
            <div class="col-xs-12 padding-top-10">
                <app-date-calendar-picker
                [exportForm]=true
                (newDateRange)="onDateSelectionChanged($event)"
              >
              </app-date-calendar-picker>
            </div>

          </div>

          <div *ngIf='startDateInvalid || endDateInvalid'>
            <div class='error-message date-error'>
              <span *ngIf='startDateInvalid'>Please choose a valid start date.</span>
            </div>

            <div class='error-message date-error'>
              <span *ngIf='endDateInvalid'>Please choose a valid end date.</span>
            </div>
          </div>

          <div class='subtext'>Time Zone (change in settings): {{selectedTimeZone.timeLabel}}</div>
          <div class='subtext timestamp-message' *ngIf="timeZoneOffset">Timestamps in exported data will be in UTC {{timeZoneOffset}}.</div>
        </div>
      </div>

      <div class='row form-section'>
        <div class='col-xs-12 col-sm-3 section-header text-align-center'>
          <div class='header-text'>Export Details</div>

          <div class='edit-sensors-link'
            id='select-sensors'
            (click)='openModal($event.target.id)'
          >
            EDIT SENSORS
          </div>
        </div>

        <div class='col-xs-12 col-sm-9 input-container table-responsive padding-top-10'>
          <table class='container-fluid sensor-table sensor-table-details table table-bordered'>
            <thead>
                <tr>
                    <th id="sensor">Sensor</th>
                    <th id="nickName">Nickname</th>
                    <th id="unitType">Unit Type</th>
                    <th id="decimals">Decimals</th>
                  </tr>
            </thead>

            <tbody>
                <tr
                *ngFor='let sensor of selectedSensors; let i = index;'
                formArrayName='sensorDecimals'
              >
                <td class='entry'>{{sensor.sensorOriginalName}}</td>
                <td class='entry'>{{sensor.displayName}}</td>
                <td class='entry'>{{getLabel(sensor.unitType)}}</td>
                <td class='entry dropdown-cell'>
                  <div>
                    <mat-form-field class="custom-export-select" >
                      <mat-select [formControlName]="i">
                        <mat-option *ngFor="let option of DecimalsOptions" [value]="option.value">
                          {{ option.label }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </td>
              </tr>
            </tbody>


            <ng-container *ngFor='let num of [1, 2, 3, 4];'>
              <tr  *ngIf='selectedSensors.length < num'>
                <td class='entry'></td>
                <td class='entry'></td>
                <td class='entry'></td>
                <td class='entry'></td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>

      <div class='row form-section'>
        <div class='col-xs-12 col-sm-3 section-header text-align-center'>
          <span class='header-text'>ASCII File Options</span>
        </div>

        <div class='col-xs-12 col-sm-9 input-container padding-top-10 xs-display-grid' >
          <mat-radio-group formControlName="UseFixedWidth">
          <div class="col-xs-12 col-sm-12 display-flex-i">
           
              <mat-radio-button [value]="true" (mousedown)="toggleFixedWidthInput(true)">Fixed Width (19-999*)
              </mat-radio-button>
              <input
                OnlyNumber
                NumberType="whole"
                type='text'
                class='fixed-width-input'
                formControlName='FixedWidth'
                [required]='UseFixedWidth'
                (input)='onFixedWidthChange()'
                (blur)="enableMessage('enableFixedWidthMessage')"
              />
              <div *ngIf="FixedWidth.invalid && enableFixedWidthMessage" class='error-message fixed-width-error' >
                Please enter a value between 19 and 999.
              </div>
            
              <mat-radio-button [value]="false" (mousedown)="toggleFixedWidthInput(false)">Delimited with</mat-radio-button>
              <div>
                <mat-form-field class='dateTimeOptions' appearance="outline">
                  <mat-select formControlName="Delimiter">
                    <mat-option *ngFor="let option of DelimiterOptions" [value]="option.value">
                      {{ option.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            
          </div>
        </mat-radio-group> 
        </div>
      </div>  


      <div class='row form-section'>
        <div class='col-xs-12 col-sm-3 section-header text-align-center'>
          <div class='header-text'>Missing Data</div>
        </div>

        <div class='col-xs-12 col-sm-9 input-container padding-top-10 display-inline-grid'>
        <div class='col-xs-12 col-sm-4'>
          <div class='col-sm-12'>
            <label class='label dropdown-label'>Float*</label>
            <input
              OnlyNumber
              NumberType="decimal"
              class='missing-data-input'
              type='text'
              formControlName='MissingFloatValue'
              (blur)="enableMessage('enableMissingFloatMessage')"
            />
          </div>
            <span class='error-message missing-float-error' *ngIf="MissingFloatValue.invalid &&
            (this.MissingFloatValue.value.length > 7 || this.enableMissingFloatMessage)">
            Enter a value between -9999999 and 9999999.
          </span>
        </div>
          <div class='col-xs-12 col-sm-4'>
          <div class='col-sm-12'>
          <label class='label dropdown-label'>Integer*</label>
          <input
            OnlyNumber
            NumberType="integer"
            type='text'
            class='missing-data-input'
            formControlName='MissingIntValue'
            (blur)="enableMessage('enableMissingIntMessage')"
            />
          </div>
            <span *ngIf="MissingIntValue.invalid &&
                  (this.MissingIntValue.value.length > 7 || this.enableMissingIntMessage)"
                  class='error-message missing-int-error'>
                  Enter a value between -9999999 and 9999999.
                </span>
          </div>

          <div class='col-xs-12 col-sm-4'>
          <div class='col-sm-12'>
              <label class='label dropdown-label'>ASCII</label>
              <input class='missing-data-input'
                type='text'
                formControlName='MissingAsciiValue'
                placeholder='&#8212;'
                maxlength='8'
              />
            </div>
            </div>
        </div>
      </div>

      <div class='row form-section'>
        <div class='col-xs-12 col-sm-3 section-header text-align-center section-header'>
          <div class='header-text'>Comments</div>
        </div>

        <div class='col-xs-12 col-sm-9 input-container padding-top-10'>
          <textarea class='comments-input'
            placeholder='Type your comments here...'
            formControlName='Comments'
          >
          </textarea>
        </div>
      </div>

      <div class='reset-export-buttons-container'>
        <button type="button"
          class='button-white reset-button'
          [disabled]='resetDisabled()'
          (click)='buildNewForm()'
        >
          Reset
        </button>

        <button type="submit" class='submit-button button-red' [disabled]='submitDisabled()'>
          Export
        </button>
      </div>
    </div>
  </form>
  </div>
  <app-export-history [exports]="exports"></app-export-history>
</div>

<div *ngIf='modal.open && modal.type === "select-well"'>
  <well-selection (close)='closeModal()'
  [(JobName)]='exportForm.controls.JobName'
  [(UidWell)]='exportForm.controls.UidWell'
  (wellSelected)='handleWellSelection($event)'>
  </well-selection>
</div>

<div *ngIf='modal.open && modal.type === "select-sensors" && sensors.length'>
  <app-sensor-select [sensors]='sensors' (close)='closeModal()'
  [selectedSensors]='selectedSensors' (saveSelected)='this.handleSensorSelection($event)'>
  </app-sensor-select>
</div>

<div class="loading-view-export-local" *ngIf="showExportWaitIndicator">
  <app-progressbar [dayRangeData] ='dayRangeResponse'></app-progressbar>
</div>
<div class="loading-view" *ngIf="minMaxOverlay">
  <app-loading-spinner></app-loading-spinner>
</div>

