<p-dialog id='dilog' [(visible)]="openDialog" [modal]="true" [responsive]="true" (onHide)="closeDialog()" [minY]="0"
    [maximizable]="false" [focusOnShow]="false" [draggable]="false" [baseZIndex]="10000"
    [contentStyle]="{'width': 'auto','max-width':'700px'}">
    <p-header>
        Settings
    </p-header>

    <div class='select-content label'>
        <div class="ui-g measurement-types">
            <div class='container-label'>Units of Measure</div>
            <div class='container container-with-border'>
                
                <div *ngFor="let sensor of sensorTypes" class='ui-g-6 temperature-selector'>
                    <div class='row'>
                        <mat-form-field class='col-xs-12 col-sm-6 label' appearance="outline">
                            <mat-label>{{sensor.settingsName}}</mat-label>
                            <mat-select  [(ngModel)]="sensor.selectedValue">
                                <mat-option *ngFor="let item of sensor.sensorUnits" [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                
            </div>
        </div>
        <div class="ui-g-12 temperature-selector">
            <div class='container-label'>Time Zone</div>
            <div class='row'>
                <mat-form-field class='col-xs-12 col-sm-12 label' appearance="outline">
                    <!-- <mat-label>Timezone</mat-label> -->
                    <mat-select #timezoneSelect [(ngModel)]="timezone" [panelClass]="'timezone-select-panel'" (openedChange)="onSelectOpened($event)">
                        <mat-option class="search-option" disabled>
                            <input  #searchInput matInput placeholder="Search..." [(ngModel)]="searchTerm" (keyup)="filterTimezones()" />
                        </mat-option>
                        <mat-option *ngFor="let item of filteredTimezones" [value]="item">
                            {{ getTimezoneLabel(item) }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>             
              
        </div>

    </div>
    <p-footer>
        <button class='primary btn-save' (click)="saveChanges()">Save</button>
    </p-footer>

</p-dialog>